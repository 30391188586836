<template>
	<div class="home">
		<div class="homeLeftMenu">
			<div class="homeLeftMenuItem" v-for="(item,index) in leftMenu" :key="index" @click="$goto(item.path)">
				<img style="width:30px" :src="item.img" alt="">
				<p style="width:40px;text-align:center;margin-top:10px">{{item.title}}</p>
			</div>
		</div>
		<div class="homeCarousel">
			<el-carousel indicator-position="outside" :height="screenWidth>=1920?'640px':'450px'" class="carousel">
				<el-carousel-item v-for="(item,index) in bannerArr" :key="index">
					<img style="width:100%" :src="`${item.image}`+'!1920x640'" alt="">
				</el-carousel-item>
			</el-carousel>
		</div>

		<div class="bannerBox">
			<el-carousel indicator-position="inside" height="175px" class="bannercarousel">
				<el-carousel-item>
					<a href="javascript:void(0);" @click="JumpPlate()"><img :src="partyBbuildingBanner.smallImage" alt=""></a>
				</el-carousel-item>
				<el-carousel-item>
					<a href="javascript:void(0);" @click="JumpPlate('esd')"><img :src="esd.smallImage" alt=""></a>
				</el-carousel-item>
				<el-carousel-item>
					<a href="javascript:void(0);" @click="JumpWeb()"><img :src="gg.smallImage" alt=""></a>
				</el-carousel-item>
				<el-carousel-item>
					<a href="javascript:void(0);" @click="JumpPlate('ztjy')"><img :src="ztjy.smallImage" alt=""></a>
				</el-carousel-item>
				<el-carousel-item>
					<a href="javascript:void(0);" @click="openUrl(shsj_hf.metaDescription)"><img :src="shsj_hf.smallImage" alt=""></a>
				</el-carousel-item>
				
			</el-carousel>
		</div>
		<TitleMore :title="['本会动态']" style="margin-top:40px" @handleMore="selfhandleMore"></TitleMore>
		<div class="baseCard homeFirst">

			<el-carousel indicator-position="outside" style="height:100%;cursor:pointer" class="homeFirst_carousel">
				<el-carousel-item v-for="(item,index) in selfDynamicBanner" :key="index">
					<div class="selfDynamicMain" @click="$goto('/detailShow',{id:item.id})">
						<img style="height:400px" :src="`${item.smallImage}`+'!750x250'" alt="">
						<h3 style="margin-top:20px">{{ item.title }}</h3>
						<!-- <TitleLine width="200px"></TitleLine> -->
						<p style="margin-top:20px">{{item.publishDate}}</p>
					</div>
				</el-carousel-item>
			</el-carousel>
		</div>
		<div class="baseCard" v-if="selfDynamicList.length>0">
			<div class="itemText" v-for="(item,index) in selfDynamicList" :key="index" style="margin-bottom: 10px;">
				<p @click="$goto('/detailShow',{id:item.id})">
					<img src="../../assets/img/list-little.png" alt="">
					<span class="itemTextContent">{{item.title}}</span>
				</p>
				<p class="itemTextTime">{{item.publishDate}}</p>
			</div>
		</div>
		<TitleMore :title="['各地动态','媒体聚焦']" @handleTab="gdHandleTab" @handleMore="gdhandleMore"></TitleMore>
		<div class="baseCard homeThree" v-if="gdDynamicListIndex!=undefined">
			<img style="width:48%;cursor:pointer" :src="`${gdDynamicListIndex.smallImage}`+'!550x370'" alt=""
				@click="$goto('/detailShow',{id:gdDynamicListIndex.id})">
			<div class="homeThreeRight">
				<p style="font-size:24px;text-align:center;
				font-weight: 500;
				line-height: 35px;
				color: #333333;
				margin-top:10px;
				overflow : hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;" @click="$goto('/detailShow',{id:gdDynamicListIndex.id})">
					{{gdDynamicListIndex.title}}
				</p>
				<p class="htrContent textsplice" @click="$goto('/detailShow',{id:gdDynamicListIndex.id})">
					{{gdDynamicListIndex.metaDescription}}
				</p>
				<div style="height: 180px;overflow: hidden;">
					<div class="itemText" v-for="(item,index) in gdDynamicList" :key="index"
						@click="$goto('/detailShow',{id:item.id})">

						<img src="../../assets/img/list-little.png" alt="">
						<span class="itemTextContent">{{item.title}}</span>

					</div>
				</div>
			</div>
		</div>
		<TitleMore :title="['通知公告']" @handleMore="tzhandleMore"></TitleMore>
		<div class="baseCard homeFour">
			<div class="homeFourCard" v-for="(item,index) in tzggList" :key="index"
				@click="$goto('/detailShow',{id:item.id})">
				<img src="../../assets/img/infoTitle.png" alt="">
				<span class="hfNum">0{{index+1}}</span>
				<div style="margin-left:20px">
					<p style="
font-size: 20px;
font-weight: bold;
color: #333333;
line-height:30px;
text-align:center">{{item.title}}</p>
					<p style="
font-size: 12px;
color: #958D8D;
line-height: 22px;" class="tzContent textsplice" v-html="item.metaDescription"></p>
				</div>
			</div>
		</div>
		<TitleMore :title="['捐款公示','捐物公示','审计公示','预决算公示']" @handleTab="handleTab" @handleMore="handleMore"></TitleMore>
		<div class="baseCard homeFive" v-if="homeFiveIndex==0">
			<el-table :data="donateData" stripe style="width: 100%">
				<el-table-column style="background:red" prop="donator_name" label="姓名" align="center" width="180">
				</el-table-column>
				<el-table-column prop="amount" label="金额(元)" align="center">
					<template slot-scope="scope">
						<p>{{scope.row.amount}}元</p>
					</template>
				</el-table-column>
				<el-table-column prop="create_date" label="时间" align="center">
				</el-table-column>
				<!-- <el-table-column
          prop="donation_intention"
          label="捐赠项目"
        >
        </el-table-column> -->
				<!-- <el-table-column
          prop="donation_intention"
          label="捐赠类别"
          width="180"
          align="center"
        > -->
				<el-table-column prop="donation_message" label="备注" width="180" align="center">
				</el-table-column>
			</el-table>
		</div>
		<div class="baseCard" v-if="homeFiveIndex==1">
			<div class="itemText" v-for="item in goodsData" :key="item.id" style="margin-bottom: 10px;"
				@click="$goto('/detailShow',{id:item.id})">
				<p>
					<img src="../../assets/img/list-little.png" alt="">
					<span class="itemTextContent">{{item.title}}</span>
				</p>

			</div>
		</div>
		<div class="baseCard" v-if="homeFiveIndex==2">
			<div class="itemText" v-for="item in auditData" :key="item.id" style="margin-bottom: 10px;"
				@click="$goto('/detailShow',{id:item.id})">
				<p>
					<img src="../../assets/img/list-little.png" alt="">
					<span class="itemTextContent">{{item.title}}</span>
				</p>

			</div>
		</div>
		<div class="baseCard" v-if="homeFiveIndex==3">
			<div class="itemText" v-for="item in budgetData" :key="item.id" style="margin-bottom: 10px;"
				@click="$goto('/detailShow',{id:item.id})">
				<p>
					<img src="../../assets/img/list-little.png" alt="">
					<span class="itemTextContent">{{item.title}}</span>
				</p>

			</div>
		</div>
		<TitleMore :title="['印象红十字']" @handleMore="videohandleMore"></TitleMore>
		<div class="baseCard homeVideo">
			<div class="homeVideoCard" v-for="(item,index) in hdyxList" :key="index" @click="playVideo(item.id)">
				<div class="biaoqian">
					<img src="../../assets/images/组 15.png" alt="">
				</div>
				<div class="bofang">
					<img src="../../assets/images/播放.png" alt="">
				</div>
				<img :src="`${item.smallImage}`+'!353x200'" alt="">
				<div style="display:flex;justify-content: space-between;margin:10px 0px 20px 0px">
					<span class="ptitle">录入时间：{{item.publishDate}}</span>
					<span class="ptitle">浏览次数：{{item.views}}次 </span>
				</div>
				<p style="font-size: 20px;font-weight: bold;color: #DB4041;text-align:center">{{item.title}}</p>
			</div>
		</div>
		<TitleMore :title="['友情链接']" :isMore="false"></TitleMore>
		<div class="baseCard homeLink">
			<el-select v-model="proValue" placeholder="--省红十字会--">
				<el-option v-for="item in provinceData" :key="item.id" :label="item.name" :value="item.name">
					<a style="font-size: 14px;
            color: #333333;
            text-decoration: none;
            float:left;
            width:100%" :href="item.url" target="_blank">{{item.name}}</a>
				</el-option>
			</el-select>
			<el-select v-model="cityValue" placeholder="--各市州红十字会--">
				<el-option v-for="item in cityData" :key="item.id" :label="item.name" :value="item.name">
					<a style="font-size: 14px; color: #333333;text-decoration: none;float:left;width:100%" :href="item.url" target="_blank">{{item.name}}</a>
				</el-option>
			</el-select>
			<el-select v-model="otherValue" placeholder="--其他友情链接--">
				<el-option v-for="item in otherData" :key="item.id" :label="item.name" :value="item.name">
					<a style="font-size: 14px;
            color: #333333;
            text-decoration: none;
            float:left;
            width:100%" :href="item.url" target="_blank">{{item.name}}</a>
				</el-option>
			</el-select>
			<a href="https://www.redcross.org.cn/" class="alink" target="_blank">
				<img src="../../assets/img/zghszh.png" alt="">
			</a>
			<!-- <a
        href="http://www.nhc.gov.cn/"
        class="alink"
        target="_blank"
      >
        <img
          src="../../assets/img/zgwsjkwyh.png"
          alt=""
        >
      </a> -->
		</div>

	</div>
</template>

<script>
	import TitleMore from "@components/TitleMore.vue";
	import TitleLine from "@components/TitleLine";
	import apiUrls from "@api";
	export default {
		data() {
			return {
				homeFiveIndex: 0,
				autoplay:false,
				leftMenu: [{
						img: require("../../assets/img/help.png"),
						title: "我要求助",
						path: "/help",
					},
					{
						img: require("../../assets/img/idonate.png"),
						title: "我要捐赠",
						path: "/idonate",
					},
					{
						img: require("../../assets/img/complaint.png"),
						title: "我要参与",
						path: "/complain",
					},
					{
						img: require("../../assets/img/bossemail.png"),
						title: "会长信箱",
						path: "/email",
					},
				],
				proValue: "",
				cityValue: "",
				otherValue: '',
				bannerArr: [],
				selfDynamicBanner: [],
				selfDynamicList: [],
				gdDynamicListIndex: {}, //各地动态
				gdDynamicList: [],
				tzggList: [], //通知公告
				hdyxList: [], //活动影像
				provinceData: [], //省链接
				cityData: [], //市链接
				otherData: [], // 其他友情链接
				donateData: [],
				goodsData: [],
				auditData: [],
				budgetData: [],
				moneyData: [],
				medicalData: [],
				screenWidth: 1920,
				partyBbuildingBanner: [],
				esd: [],
				gg: [],
				ztjy: {},
				shsj_hf:{}
			};
		},

		components: {
			TitleMore,
			TitleLine,
		},

		mounted() {
			this.getBanner();
			this.getselfDyList("bhdt", 11);
			this.getselfDyList("gddt", 10);
			this.getselfDyList("tzgg", 3);
			this.getselfDyList("sy_gmyljg_xxgs");
			this.getselfDyList("sy_cw_xxgs");
			this.getselfDyList("hdyx", 3);
			this.getFriendlink("province");
			this.getFriendlink("city");
			this.getFriendlink("other");
			this.getdonateMsg();
			this.getgoodsMsg();
			this.getauditMsg();
			this.getbudgetMsg();
			this.getScreen();
			this.getPartyBbuildingBanner()
		},

		methods: {
			JumpPlate(type) {
				localStorage.removeItem('bread');
				this.$firstBread = true;
				if (type == 'esd') {
					let bread = [{
						title: '认真学习贯彻党的二十大精神',
						path: '/esd'
					}];
					localStorage.setItem('bread', JSON.stringify(bread));
					this.$router.push({
						path: "/esd",
						query: {}
					});
				}else if(type == 'ztjy'){
					let bread = [{
						title: '学习贯彻习近平新时代中国特色社会主义思想主题教育',
						path: '/ztjy'
					}];
					localStorage.setItem('bread', JSON.stringify(bread));
					this.$router.push({
						path: "/ztjy",
						query: {}
					});
					
				} else {
					let bread = [{
						title: '学党史 悟思想 跟党走',
						path: '/partyBuilding'
					}];
					localStorage.setItem('bread', JSON.stringify(bread));
					this.$router.push({
						path: "/partyBuilding",
						query: {}
					});
				}
			},
			openUrl(url){
				window.open(url)
			},
			JumpWeb() {
				window.open(
					'https://m.jkzg2030.cn/topic/page/29?title=%E5%AF%BB%E6%89%BE%E6%9C%80%E7%BE%8E%E6%95%91%E6%8A%A4%E5%91%98',
					'_blank');
			},

			getScreen() {
				this.screenWidth = window.screen.width;
			},
			playVideo(id) {
				this.$router.push({
					path: "/videoShow",
					query: {
						id
					}
				});
			},
			handleTab(index) {
				this.homeFiveIndex = index;
			},
			handleMore(index) {
				this.$router.push("/infoopen");
				// if (index == 0) {
				//   this.$router.push("/donateInfo");
				// } else if (index == 1) {
				//   this.$router.push({
				//     path: "/textDetail",
				//     query: { name: "财务公示", type: "sy_cw_xxgs" },
				//   });
				// } else {
				//   this.$router.push({
				//     path: "/textDetail",
				//     query: { name: "冠名医疗机构公示", type: "sy_gmyljg_xxgs" },
				//   });
				// }
			},
			selfhandleMore() {
				this.$router.push({
					path: "/imgDetail",
					query: {
						name: "本会动态",
						type: "bhdt"
					},
				});
			},
			gdhandleMore(index) {
				if (index == 0) {
					this.$router.push({
						path: "/imgDetail",
						query: {
							name: "各地动态",
							type: "gddt"
						},
					});
				} else {
					this.$router.push({
						path: "/imgDetail",
						query: {
							name: "媒体聚焦",
							type: "mtbd"
						},
					});
				}
			},
			tzhandleMore() {
				this.$router.push({
					path: "/textDetail",
					query: {
						name: "通知公告",
						type: "tzgg"
					},
				});
			},
			videohandleMore() {
				this.$router.push({
					path: "/videoDetail",
					query: {
						name: "印象红十字",
						type: "hdyx"
					},
				});
			},
			gdHandleTab(index) {
				index == 0 ?
					this.getselfDyList("gddt", 10) :
					this.getselfDyList("mtbd", 10);
			},
			//首页banner
			getBanner() {
				apiUrls.homeBanner({
					type: "homepage"
				}).then((res) => {
					this.bannerArr = res.results;
				});
			},
			getdonateMsg() {
				apiUrls
					.donateMsg({
						pageNumber: 1,
						pageSize: 5,
						donateName: this.donateName ?? '',
						startDate: this.startDate ?? '',
						endDate: this.endDate ?? '',
					})
					.then((res) => {
						this.donateData = res.results.list;
						// this.total = res?.results?.pager?.totalElements?? 0;
					});
				// apiUrls.selfDynamicList({node:'xxgk_jkgs', page: 0, size: 8 }).then((res) => {
				//   this.donateData = res.results.data;
				//   console.log(this.donateData)
				// });
			},
			getgoodsMsg() {
				apiUrls.selfDynamicList({
					node: 'xxgk_jwgs',
					page: 0,
					size: 8
				}).then((res) => {
					this.goodsData = res.results.data;
				});
			},
			getauditMsg() {
				apiUrls.selfDynamicList({
					node: 'xxgk_sjbggs',
					page: 0,
					size: 8
				}).then((res) => {
					this.auditData = res.results.data;
				});
			},
			getbudgetMsg() {
				apiUrls.selfDynamicList({
					node: 'xxgk_cwgs',
					page: 0,
					size: 8
				}).then((res) => {
					this.budgetData = res.results.data;
				});
			},
			//本会动态
			getselfDyList(type, size = 5) {
				let _this = this;
				apiUrls.selfDynamicList({
					node: type,
					size
				}).then((res) => {
					switch (type) {
						case "bhdt":
							this.selfDynamicBanner = [];
							this.selfDynamicList = [];
							let data = res.results.data;
							for (let i = 0; i < data.length; i++) { //循环,如果 轮播图小于3个的时候
								if (this.selfDynamicBanner.length < 3 && data[i].smallImage) {
									this.selfDynamicBanner.push(data[i])
								} else { //如果大于3个,就放到列表
									this.selfDynamicList.push(data[i])
								}
							}
							break;
						case "gddt":
							// this.gdDynamicListIndex = res.results.data[0];
							// this.gdDynamicList = res.results.data.slice(1);
							this.gdDynamicListIndex = undefined;
							this.gdDynamicListIndex = [];
							this.gdDynamicList = [];
							res.results.data.map(v => {
								if (v.smallImage && _this.gdDynamicListIndex.smallImage == undefined) {
									_this.gdDynamicListIndex = v
								} else {
									_this.gdDynamicList.push(v)
								}
							})
							break;
						case "mtbd":
							this.gdDynamicListIndex = undefined;
							this.gdDynamicListIndex = [];
							this.gdDynamicList = [];
							res.results.data.map(v => {
								if (v.smallImage && _this.gdDynamicListIndex.smallImage == undefined) {
									_this.gdDynamicListIndex = v
								} else {
									_this.gdDynamicList.push(v)
								}
							})
							break;
						case "tzgg":
							this.tzggList = res.results.data;
							break;
						case "hdyx":
							this.hdyxList = res.results.data;
							break;
						case "sy_gmyljg_xxgs":
							this.medicalData = res.results.data;
							break;
							// case "sy_jx_xxgs":
							//   this.donateData = res.results.data;
							//   break;
						case "sy_cw_xxgs":
							this.moneyData = res.results.data;
							break;
					}
				});
			},
			//友情链接
			getFriendlink(type) {
				apiUrls.friendlink({
					type
				}).then((res) => {
					type == "province" ?
						(this.provinceData = res.results) :
						type == 'other' ? (this.otherData = res.results) : (this.cityData = res.results);
				});
			},
			getPartyBbuildingBanner() {
				apiUrls.yjData({
					node: 'xds_hf'
				}).then((res) => {
					this.partyBbuildingBanner = res.results.data
				});

				apiUrls.yjData({
					node: 'ersd_hf'
				}).then((res) => {
					this.esd = res.results.data
				});

				apiUrls.yjData({
					node: 'gg_hf'
				}).then((res) => {
					this.gg = res.results.data
				});

				apiUrls.yjData({
					node: 'ztjy_hf'
				}).then((res) => {
					this.ztjy = res.results.data
				});
				
				apiUrls.yjData({
					node: 'shsj_hf'
				}).then((res) => {
					this.shsj_hf = res.results.data;
				});
			}
		},
	};
</script>
<style lang="less" scoped>
	.itemTextContent {
		&:hover {
			color: #d1292e;
		}
	}

	.textsplice(@height: 60px) {
		position: relative;
		line-height: 30px; //可变
		max-height: @height; //可变
		overflow: hidden;
		width: auto; //可变
	}

	// .textsplice::after {
	//   content: "...";
	//   position: absolute;
	//   // bottom: 0;
	//   font-size: 18px;
	//   right: 7px; //可变
	//   // padding-right: 124px; //可变
	//   //兼容性处理
	//   background: -webkit-linear-gradient(left, transparent,rgba(0,0,0,0));
	//   background: -o-linear-gradient(right, transparent, rgba(0,0,0,0));
	//   background: -moz-linear-gradient(right, transparent,rgba(0,0,0,0));
	//   background: linear-gradient(to right, transparent,rgba(0,0,0,0));
	// }
	.home {
		background-color: #f9f9f9;

		.carousel {
			position: relative;

			/deep/ .el-carousel__indicators {
				position: absolute;
				bottom: 25px !important;

				// left: 45%;
				.el-carousel__indicator {
					width: 10px !important;
					height: 10px !important;
					border-radius: 50%;
					padding: 0px;
					background: #a0a0a0;
					margin: 0px 20px;

					.el-carousel__button {
						width: 100%;
						height: 100%;
						border-radius: 50%;
						opacity: 0;
					}
				}

				.is-active {
					width: 10px;
					height: 10px;
					border-radius: 50%;
					background-color: #d1292e;
				}
			}
		}

		.homeLeftMenu {
			position: fixed;
			top: 40%;
			left: 0px;
			border-radius: 0px 24px 24px 0px;
			background: #ffffff;
			box-shadow: 0px 6px 18px 0px rgba(14, 14, 14, 0.27);
			padding: 10px;
			z-index: 100;

			.homeLeftMenuItem {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				margin: 30px 0px;
				cursor: pointer;
			}

			.homeLeftMenuItem:first-child {
				margin-top: 10px;
			}

			.homeLeftMenuItem:last-child {
				margin-bottom: 10px;
			}
		}

		.homeFirst {
			padding: 0;
			width: 1200px;
			min-height: 400px;

			/deep/ .el-carousel__container {
				height: 480px;
			}

			.homeFirst_carousel {
				position: relative;
				padding-bottom: 20px;

				/deep/ .el-carousel__indicators {
					position: absolute;
					bottom: 115px !important;
					left: 44%;

					// margin: 0 auto;
					.el-carousel__indicator {
						width: 10px !important;
						height: 10px;
						border-radius: 50%;
						padding: 0px;
						background: #a0a0a0;
						margin: 0px 20px;

						.el-carousel__button {
							// width: 100%;
							// height: 100%;
							border-radius: 50%;
							opacity: 0;
						}
					}

					.is-active {
						width: 10px;
						height: 10px;
						border-radius: 50%;
						background-color: #d1292e;
					}
				}
			}
		}

		.bannerBox {
			width: 1200px;
			height: 175px;
			margin: 50px auto 0;

			.bannerCarousel {
				width: 100%;
				height: 100%;

				/deep/ .el-carousel__container {
					height: 100%;
					img{
						width: 1200px;
						height: 175px;
					}
				}
				
			}
		}

		.homeCarousel {
			min-width: 1200px;
			height: auto;
		}

		.homeThree {
			display: flex;

			.homeThreeRight {
				width: 48%;
				margin-left: 4%;

				p {
					cursor: pointer;
				}

				.itemText {
					padding: 10px 0px;
					margin-bottom: 10px !important;
				}

				.htrContent {
					// height: 150px;
					font-size: 14px;
					color: #958d8d;
					border-bottom: 1px dotted #dcdcdc;
					padding: 10px 0px;
					line-height: 30px;
					.textsplice(110px);
				}
			}
		}

		.homeFour {
			display: flex;
			justify-content: space-between;

			.homeFourCard {
				width: 30%;
				display: flex;
				position: relative;
				padding: 10px;

				&:hover {
					transform: translateY(-5px);
					box-shadow: 5px 5px 5px #ccc;
				}

				.tzContent {
					.textsplice();
				}
			}

			.hfNum {
				position: absolute;
				font-size: 36px;
				font-family: Impact;
				font-weight: 400;
				color: #ffffff;
				line-height: 5px;
				top: 40px;
				left: 10px;
			}
		}

		.homeFive {

			/deep/.el-table th,
			.el-table tr {
				background-color: #f4e2c8;
				color: #ae6d23;
				font-size: 18px;
				font-weight: bold;
			}
		}

		.homeVideo {
			display: flex;
			justify-content: space-between;

			&:after {
				content: '';
				/*这个宽度和子元素宽度一致*/
				width: 30%;
			}

			.homeVideoCard {
				position: relative;
				display: flex;
				flex-direction: column;
				justify-content: center;
				width: 30%;
				padding: 10px;

				.bofang {
					position: absolute;
					left: 50%;
					top: 110px;
					transform: translate(-50%, -50%);
					z-index: 1000;
				}

				.biaoqian {
					position: absolute;
					top: 20px;
					left: 0;
				}

				&:hover {
					transform: translateY(-5px);
					box-shadow: 5px 5px 5px #ccc;
				}

				.ptitle {
					font-size: 12px;
					font-weight: 500;
					color: #505052;
				}
			}
		}

		.homeLink {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.alink {
				font-size: 14px;
				color: #333333;
				text-decoration: none;
				display: inline-block;
			}

			/deep/.el-select .el-input__inner:focus {
				border-color: rgb(219, 64, 65);
			}
		}

		.selfDynamicMain {
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;

			h3 {
				font-weight: bold;
				color: #333333;
				font-size: 20px;
				height: 30px;
				text-align: center;
				margin-top: 5px;
			}

			p {
				font-size: 16px;
				text-align: center;
				color: #ababab;
			}
		}
	}
</style>